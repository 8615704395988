import React, {useEffect, useState} from 'react';
import {NavLink, AppShell, SegmentedControl} from '@mantine/core';
import {
    IconLogout,
} from '@tabler/icons-react';
import classes from './Navbar.module.css';
import {useNavigate} from "react-router-dom";
import {TabItem, useConfig} from "../ConfigContext";
import {useMediaQuery} from "@mantine/hooks";
import {useAuth} from "../AuthContext";
import PlanDisplay from "./PlanDisplay";


const Navbar = ({toggle, opened, setOpened}: any) => {
    const {isAuthenticated, profile, logout} = useAuth();
    const {navigationLinks} = useConfig();
    const navigate = useNavigate();
    const defaultSectionKey = Object.keys(navigationLinks).find(key => navigationLinks[key].default) || Object.keys(navigationLinks)[0];
    const [section, setSection] = useState<keyof typeof navigationLinks>(defaultSectionKey);
    const [activeLabel, setActiveLabel] = useState<string>(''); // New state for active label
    const [isNavbarOpen, setNavbarOpen] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    useEffect(() => {
        if (isSmallScreen) {
            setNavbarOpen(false); // Initially hide navbar on small screens
            //toggle();
            setOpened(false);
        }
    }, [isSmallScreen]);

    const segments = Object.entries(navigationLinks)
        .filter(([name, section]) => !section.disabled && (!section.private || isAuthenticated))
        .map(([key, {label}]) => ({
            label,
            value: key
        }));
    const handleNavLinkClick = (label: string, link: string) => {
        setActiveLabel(label); // Update the active label
        navigate(link); // Navigate to the link's path
        if (isSmallScreen && opened) {
            // setNavbarOpen(false); // Hide navbar on small screens when a link is clicked
            toggle();
        }
    };

    const navbarLinkData =  (link: string) => {
        switch (link) {
            case '/billing':
                return " | " + profile?.accountBalance + " credits";
            case '/profile':
                return " | " + profile?.plan;
            default:
                return ""; // or some other default value
        }
    }

    const createFormedNavLink = (item: TabItem) => {
        return (
            <NavLink
                key={item.label}
                label={
                    <div className={classes.linkContent}>
                        {React.createElement(item.icon, {className: classes.linkIcon, stroke: 1.5})}
                        <span>{item.label} {navbarLinkData(item.link)}</span>
                    </div>
                }
                onClick={(event) => {
                    event.preventDefault();
                    handleNavLinkClick(item.label, item.link);
                }}
                active={item.label === activeLabel} // Manage active state as before
                style={{display: 'flex', alignItems: 'center', gap: '10px', margin: '5px'}}
            />
        );
    }

    const links = navigationLinks[section].items
        .filter((item: TabItem) => !item.disabled && (!item.private || isAuthenticated))
        .map(createFormedNavLink);

    const handleSectionChange = (value: string) => {
        setSection(value);
    };

    const handleLogout = async () => {
        logout();
        navigate('/'); // Redirect to the login page after logout
    };

    return (
        <AppShell.Navbar className={classes.navbar} p="md" hidden={!isNavbarOpen}>
        {/*<AppShell.Navbar className={classes.navbar} p="md">*/}
            {
                segments.length > 1
                    ?
                    <SegmentedControl
                        value={section as string}
                        onChange={handleSectionChange}
                        data={segments}
                        style={{marginBottom: '20px'}} // You can adjust the styling as needed
                    />
                    :
                    <></>
            }
            <div className={classes.navbarMain}>
                {links}
            </div>
            {
                isAuthenticated ?
                    <div className={classes.footer}>
                        <NavLink
                            label={
                                <div className={classes.linkContent}>
                                    <IconLogout className={classes.linkIcon} stroke={1.5}/>
                                    <span>Logout</span>
                                </div>
                            }
                            onClick={handleLogout}
                        />
                    </div>
                    :
                    <></>
            }
        </AppShell.Navbar>
    );
}

export default Navbar;
