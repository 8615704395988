// src/pages/Profile.tsx

import React, { useEffect, useState } from 'react';
import {
    TextInput,
    Button,
    Card,
    Title,
    SimpleGrid,
    Space,
    Fieldset,
    Text,
    LoadingOverlay,
    Tabs,
} from '@mantine/core';
import { useAuth } from '../AuthContext';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import {
    IconBrandLinkedin,
    IconBrandTwitter,
    IconBrandFacebook,
    IconBrandInstagram,
} from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';

const Profile = () => {
    // Hooks and state variables
    const { isAuthenticated, profile, refreshProfile, profileUpdating } = useAuth();

    const [isUpdating, setIsUpdating] = useState(false);
    const [updateConfirmation, setUpdateConfirmation] = useState('');

    // LinkedIn authentication states
    const [linkedInAuthenticated, setLinkedInAuthenticated] = useState(false);
    const [linkedInMessage, setLinkedInMessage] = useState('');

    const CLIENT_ID = "86k6lickr7jpnn";
    // const CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID!;
    const REDIRECT_URI = `${window.location.origin}/linkedin-auth`;
    console.log(REDIRECT_URI)
    const isSmallDevice = useMediaQuery('(max-width: 1450px)');

    // Function definitions
    const handleLinkedInLogin = () => {
        const scope = 'w_member_social';
        const state = uuidv4();
        sessionStorage.setItem('linkedin_oauth_state', state);
        const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
            REDIRECT_URI
        )}&state=${state}&scope=${encodeURIComponent(scope)}`;

        // Open a popup window
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        window.open(
            linkedInAuthUrl,
            'LinkedInAuth',
            `width=${width},height=${height},top=${top},left=${left}`
        );
    };

    const loadProfile = () => {
        refreshProfile(1);
    };

    const handleSave = async () => {
        setIsUpdating(true);
        setUpdateConfirmation('');
        try {
            // Implement profile update logic here
            setUpdateConfirmation('Profile updated successfully!');
        } catch (error) {
            setUpdateConfirmation('Failed to update profile.');
            // Handle error appropriately
        }
        setIsUpdating(false);
    };

    // useEffect Hooks
    useEffect(() => {
        loadProfile();
    }, []);

    useEffect(() => {
        const receiveMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) {
                // Ignore messages from other origins
                return;
            }

            const { type, code, state } = event.data;

            if (type === 'LinkedInAuth' && code) {
                const storedState = sessionStorage.getItem('linkedin_oauth_state');
                if (state !== storedState) {
                    setLinkedInMessage('Invalid state parameter');
                    return;
                }
                // Proceed with authentication
                axios
                    .post('http://localhost:5000/auth/linkedin', { code, userId: profile.id })
                    .then(() => {
                        setLinkedInAuthenticated(true);
                        setLinkedInMessage('Successfully connected to LinkedIn!');
                    })
                    .catch(() => {
                        setLinkedInMessage('Error during LinkedIn authentication');
                    });
            }
        };

        window.addEventListener('message', receiveMessage);

        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, [profile?.id]);

    // Place conditional return after Hooks and function definitions
    if (!isAuthenticated) {
        return null;
    }

    return (
        <Card
            shadow="sm"
            padding="lg"
            style={{
                margin: 'auto',
                width: isSmallDevice ? '90%' : '1000px',
                maxWidth: '1200px',
            }}
        >
            <LoadingOverlay
                visible={profileUpdating}
                zIndex={1000}
                overlayProps={{ radius: 'sm', blur: 2 }}
            />

            <Tabs defaultValue="profile">
                <Tabs.List>
                    <Tabs.Tab value="profile"><Title order={2}>Profile</Title></Tabs.Tab>
                    {/*<Tabs.Tab value="social">Social</Tabs.Tab>*/}
                </Tabs.List>

                {/* Profile Tab */}
                <Tabs.Panel value="profile" pt="xs">
                    <SimpleGrid cols={1} spacing="lg" style={{ alignItems: 'flex-start' }}>
                        <Fieldset variant="filled" radius="md" style={{ height: '100%' }}>
                            {/*<Space h="xl" />*/}
                            {/*<Title order={2}>Profile</Title>*/}
                            {/*<Space h="md" />*/}

                            <TextInput
                                label="Email"
                                value={profile?.id}
                                readOnly
                                description="This field can't be changed due to security policy."
                                variant="filled"
                            />
                            <Space h="md" />
                            <TextInput
                                label="First Name"
                                value={profile?.firstName}
                                placeholder="Your first name"
                                disabled
                            />
                            <Space h="md" />
                            <TextInput
                                label="Last Name"
                                value={profile?.lastName}
                                placeholder="Your last name"
                                disabled
                            />
                            <Space h="md" />
                            <TextInput
                                label="Organization Legal Name"
                                value={profile?.organization}
                                placeholder="Your organization"
                                disabled
                            />
                            <Space h="xl" />
                            <Button onClick={handleSave} variant="outline" disabled={isUpdating}>
                                {isUpdating ? 'Updating...' : 'Update Profile'}
                            </Button>
                            {updateConfirmation && (
                                <Text size="xs" style={{ marginTop: '10px' }}>
                                    {updateConfirmation}
                                </Text>
                            )}
                        </Fieldset>
                    </SimpleGrid>
                </Tabs.Panel>

                {/* Social Tab */}
                <Tabs.Panel value="social" pt="xs">
                    <Fieldset variant="filled" radius="md" style={{ height: '100%' }}>
                        <Space h="xl" />
                        <Title order={2}>Social Networks</Title>
                        <Space h="md" />

                        <SimpleGrid cols={1} spacing="lg" style={{ alignItems: 'flex-start' }}>
                            {/* LinkedIn Integration */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconBrandLinkedin size={32} />
                                <Text size="lg" style={{ marginLeft: '10px' }}>
                                    LinkedIn
                                </Text>
                                <div style={{ flexGrow: 1 }} />
                                {linkedInAuthenticated ? (
                                    <Text color="green">Connected</Text>
                                ) : (
                                    <Button onClick={handleLinkedInLogin} variant="outline" size="sm">
                                        Connect
                                    </Button>
                                )}
                            </div>
                            {linkedInMessage && (
                                <Text size="sm" style={{ marginTop: '10px' }}>
                                    {linkedInMessage}
                                </Text>
                            )}

                            {/* Twitter Placeholder */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconBrandTwitter size={32} />
                                <Text size="lg" style={{ marginLeft: '10px' }}>
                                    Twitter
                                </Text>
                                <div style={{ flexGrow: 1 }} />
                                <Text color="dimmed">Coming Soon</Text>
                            </div>

                            {/* Facebook Placeholder */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconBrandFacebook size={32} />
                                <Text size="lg" style={{ marginLeft: '10px' }}>
                                    Facebook
                                </Text>
                                <div style={{ flexGrow: 1 }} />
                                <Text color="dimmed">Coming Soon</Text>
                            </div>

                            {/* Instagram Placeholder */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IconBrandInstagram size={32} />
                                <Text size="lg" style={{ marginLeft: '10px' }}>
                                    Instagram
                                </Text>
                                <div style={{ flexGrow: 1 }} />
                                <Text color="dimmed">Coming Soon</Text>
                            </div>
                        </SimpleGrid>
                    </Fieldset>
                </Tabs.Panel>
            </Tabs>
        </Card>
    );
};

export default Profile;
